import React from 'react'
import styles from './styles.module.css'
import { Link } from "gatsby";
import ChartIcon from "../ChartIcon";
import { FavoriteButton } from "../FavoriteButton";


const ChartsList = ({posts, isExpanded, isEmbed=false}) => (
  <div className={styles.chartsList}>
    <ul className={isEmbed && styles.embedList}>
    {posts.map(function (post, i){
      const displayVariations = !isExpanded && (post.variations > 0);
      return (
        <li key={i}>
          <Link to={"/" + post.slug} className="link" title={displayVariations ? "has " + post.variations + " variations" : null}>
            <aside>
              <ChartIcon slug={post.slug} showStack={displayVariations} isVariation={post.variations || post.variations === 0 ? false : true} />
            </aside>
          </Link>
          <div className={styles.titleContainer}>  
            <div className={styles.titleFrame}>
              <Link to={"/" + post.slug} className="link" title={displayVariations ? "has " + post.variations + " variations" : null}>
                {isEmbed 
                  ? <h3 className={styles.title}>{post.title}</h3>
                  : <h2 className={styles.title}>{post.title}</h2>
                }
              </Link>
            </div>
            {!isEmbed && <FavoriteButton chartId={post.slug}/> }
          </div>
          <Link to={"/" + post.slug} className="link" title={displayVariations ? "has " + post.variations + " variations" : null}>
            <p className={styles.description}>{post.excerpt}</p>
          </Link>
        </li>
    )})}
    </ul>
  </div>
)

export default ChartsList
