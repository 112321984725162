import React, { useContext } from "react";
import StarIcon from "./icon-star-regular.svg";
import StarFullIcon from "./icon-star-solid.svg";
import styles from "./styles.module.css";
import {saveFavChartsInStorage} from "../../utils";
import FavoriteContext from "../../favoriteContext";

export const FavoriteButton = ({chartId}) => {
  // load all charts from context to determine if the chart is selected
  const {charts, setCharts} = useContext(FavoriteContext); 
  const isPressed = charts[chartId] ? true : false;

  const handleClick = () => {
    // updating chart state in React context and browser local storage
    const newCharts = Object.assign({}, charts);
    if (!isPressed) {
      newCharts[chartId] = [];
    } else {
      delete newCharts[chartId];
    }
    saveFavChartsInStorage(newCharts); // save in local storage
    setCharts(newCharts); // update context
  }

  return (
    <span className={styles.icon} onClick={handleClick} title={isPressed ? "unfavorite" : "favorite"}>
      { isPressed ? <StarFullIcon /> : <StarIcon /> }
    </span>
  );
};
