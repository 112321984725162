import React, { useState } from "react";
import styles from "./styles.module.css";
import { Link } from "gatsby";
import ChartIcon from "../ChartIcon";
import ExpandIcon from "./icon-expand.svg";
import CollapseIcon from "./icon-collapse.svg";


const ChartsGrid = ({posts, isExpanded}) => {
  const [isFullWidth, setIsFullWidth] = useState(false); // maybe read button state from cookies?
  return (
    <>
      <div className={styles.toggleFullWidth}>
        <span
          className={styles.toggleIcon}
          onClick={() => setIsFullWidth(!isFullWidth)}
          title={isFullWidth ? "narrow grid" : "expand grid"}
        >
          {isFullWidth ? <CollapseIcon /> : <ExpandIcon />}
        </span>
      </div>
      <div className={`${styles.gridContainer} ${isFullWidth ? styles.fullWidth : ''}`}>
        {posts.map(function (post, i) {
          const displayVariations = !isExpanded && (post.variations > 0);
          return (
          <Link to={`/${post.slug}`} key={i} className={styles.link} title={displayVariations ? "has " + post.variations + " variations" : null}>
            <div className={styles.gridItem}>
                <ChartIcon className={styles.icon} slug={post.slug} showStack={displayVariations} isVariation={ post.variations || post.variations === 0 ? false : true}/>
              <h4 className={styles.title}>{post.title}</h4>
            </div>
          </Link>
        )})}
      </div>
    </>
  )
}

export default ChartsGrid
